import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-apollo';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import ByLine from '@atlassian/analytics-byline';

import {
	ANALYTICS_BYLINE_EXPERIENCE,
	ANALYTICS_DIALOG_EXPERIENCE,
} from '@confluence/experience-tracker';
import { getMark } from '@confluence/performance';
import { SSR_PARTIAL_COMPONENT, SetSSRPartialSuccess } from '@confluence/ssr-utilities';

import { SSREvent, useHideByline } from '../../hooks';
import { CONTENT_ANALYTICS_DIALOG_METRIC } from '../../perf.config';
import type {
	ContentAnalyticsViewersUnifiedQuery as GraphqlQueryData,
	ContentAnalyticsViewersUnifiedQueryVariables as GraphqlQueryVariables,
} from '../../queries/__types__/ContentAnalyticsViewersUnifiedQuery';
import { ContentAnalyticsViewersUnifiedQuery as ViewersQuery } from '../../queries/ContentAnalyticsViewersUnifiedQuery.graphql';
import { LazyAnalyticsDialog } from '../AnalyticsDialog';
import { DialogPillName } from '../AnalyticsDialog/constants';
import { getViewersCount } from '../utils';
import { MentionRemindersNudgeWrapper, NudgeLocation } from '../MentionRemindersNudgeWrapper';
import { useMentionRemindersEligible } from '../../hooks/useMentionRemindersEligible';

import { CLICKED_BYLINE_SSR_MARK, TestId } from './constants';
import type { UseAnalyticsByLineHook } from './UseAnalyticsByLineHook';

export const useAnalyticsByLine: UseAnalyticsByLineHook = ({
	contentId,
	skip,
	wasComponentSSRed,
	openMentionRemindersModal,
}) => {
	const [selectedPill, onPillSelected] = useState<DialogPillName>(DialogPillName.VIEWS);
	const [dialogReferenceDate, setDialogReferenceDate] = useState(new Date());

	const [shouldRefetch, setShouldRefetch] = useState(false);
	const triggerRefetch = useCallback(() => {
		setShouldRefetch(true);
	}, [setShouldRefetch]);

	useEffect(() => {
		setDialogReferenceDate(new Date());
		setShouldRefetch(false);
		onPillSelected(DialogPillName.VIEWS);
	}, [
		contentId,
		// Preserved for compatibility with the AnalyticsByLine implementation
		// before this source code was moved from there to here:
		wasComponentSSRed,
	]);

	const { data, loading, error, refetch } = useQuery<GraphqlQueryData, GraphqlQueryVariables>(
		ViewersQuery,
		{
			variables: {
				contentId,
			},
			context: {
				single: true,
			},
			skip,
		},
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// We want to allow the data to refresh once upon opening the dialog for the first time, but never
	// beyond that. This one-time refresh exists so that the data displayed in the dialog matches the
	// data being displayed in the byline.
	const hasRefetched = useRef(false);

	useMentionRemindersEligible(contentId);

	useEffect(() => {
		if (shouldRefetch && !hasRefetched.current) {
			void refetch({ contentId });

			hasRefetched.current = true;
		} else if (!shouldRefetch && hasRefetched.current) {
			hasRefetched.current = false;
		}
	}, [contentId, refetch, shouldRefetch]);

	const hideByLine = useHideByline(loading, data, error);

	const count = getViewersCount(data);

	const onPopupTriggerSSREvent = useCallback(
		(event: SSREvent) => {
			switch (event) {
				case SSREvent.CLICK:
					CONTENT_ANALYTICS_DIALOG_METRIC.start({
						startTime: getMark(CLICKED_BYLINE_SSR_MARK),
					});
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'clicked',
							actionSubject: 'AnalyticsButton',
							source: 'viewPageScreen',
							objectId: contentId,
							attributes: {
								from: 'fromByline',
								isSSR: wasComponentSSRed,
							},
						},
					}).fire();
					break;

				case SSREvent.FOCUS:
				case SSREvent.HOVER:
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'hovered',
							actionSubject: 'AnalyticsButton',
							source: 'page',
							objectId: contentId,
							attributes: {
								from: 'fromByline',
								isSSR: wasComponentSSRed,
								tabFocus: event === SSREvent.FOCUS,
							},
						},
					}).fire();
					break;
			}
		},
		[contentId, createAnalyticsEvent, wasComponentSSRed],
	);

	return {
		error,
		loading,

		//
		// Popup content
		//

		popupContent: ({ onClose, zIndex }) => (
			<LazyAnalyticsDialog
				contentId={contentId}
				onClose={onClose}
				referenceDate={dialogReferenceDate}
				selectedPill={selectedPill}
				onPillSelected={onPillSelected}
				requestBylineCountRefetch={triggerRefetch}
				zIndex={zIndex}
				openMentionRemindersModal={openMentionRemindersModal}
			/>
		),

		popupContentExperience: ANALYTICS_DIALOG_EXPERIENCE,

		onPopupContentClose: () => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'dismissed',
					actionSubject: 'analyticsPageModal',
					actionSubjectId: 'analytics',
					objectId: contentId,
					source: 'analyticsPageModal',
				},
			}).fire();
		},

		//
		// Popup trigger
		//

		popupTrigger: ({ onClick }) =>
			hideByLine || (
				<MentionRemindersNudgeWrapper
					nudgeName={NudgeLocation.ANALYTICS_BYLINE}
					onConfirm={() => {
						onClick?.();
						onPillSelected(DialogPillName.MENTIONS);
					}}
					contentId={contentId}
				>
					<ByLine count={count} onClick={onClick} isBylineComponent testId={TestId.BYLINE} />
					<SetSSRPartialSuccess name={SSR_PARTIAL_COMPONENT.AnalyticsByLine} />
				</MentionRemindersNudgeWrapper>
			),

		popupTriggerExperience: ANALYTICS_BYLINE_EXPERIENCE,

		onPopupTriggerSSREvent,
	};
};
